import styled from "styled-components"

const OpacityLayer = styled.div`
  position: fixed;
  opacity: ${props => (props.isOpen ? "1" : "0")};
  left: 0;
  top: 90px;
  right: 0;
  bottom: 0;
  background: #0004;
  z-index: ${props => (props.isOpen ? "5" : "-1")};
  transition: 0.25s;
`
export default OpacityLayer
