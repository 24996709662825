import styled from "styled-components"
import React from "react"

const arrowRightSquared = (
  <svg viewBox="0 0 448 512">
    <path
      d="M251.3 148.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L297.4 240H112C103.2 240 96 247.2 96 255.1C96
   264.8 103.2 272 112 272h185.4l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.62s16.38 6.25 22.62 0l96-96C350.4 264.2 352 260.1 352 255.1s-1.562-8.184-4.688-11.31L251.3 148.7zM384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96C448 60.65 419.3 32 384 32zM416 416c0 17.64-14.36 32-32 32H64c-17.64 
  0-32-14.36-32-32V96c0-17.64 14.36-32 32-32h320c17.64 0 32 14.36 32 32V416z"
    />
  </svg>
)

const StyledHamburgerMenu = styled.div`
  /* ... */
  color: white;
  font-size: 30px;
  font-weight: 600;
  font-family: var(--avenir-font-stack);
  padding-left: 20px;
  padding-top: 40px;
  font-variant: small-caps;
  h2 {
    display: flex;
    gap: 0.5em;
    align-items: centerl;
  }
  h2 + h2 {
    margin-top: 16px;
  }
  svg {
    fill: currentColor;
    height: 30px;
  }
  a {
    color: currentColor;
  }
`

function HamburgerMenu({ setHamburgerMenuIsOpen }) {
  return (
    <StyledHamburgerMenu>
      {/* <h2 className="mediumHeading">Menu</h2> */}
      <h2>
        {arrowRightSquared}{" "}
        <a
          href="#services"
          onClick={() => {
            setHamburgerMenuIsOpen(false)
          }}
        >
          {" "}
          Nos Domaines{" "}
        </a>
      </h2>
      <h2>
        {arrowRightSquared}
        <a
          onClick={() => {
            setHamburgerMenuIsOpen(false)
          }}
          href="#lawyers"
        >
          Les Avocats
        </a>
      </h2>
      {/*   <h2>
        {arrowRightSquared}
        <a
          onClick={() => {
            setHamburgerMenuIsOpen(false)
          }}
          href="#news"
        >
          Actualités
        </a>
      </h2> */}
      <h2>
        {arrowRightSquared}
        <a
          onClick={() => {
            setHamburgerMenuIsOpen(false)
          }}
          href="#contact"
        >
          Nous Contacter
        </a>
      </h2>
    </StyledHamburgerMenu>
  )
}

const StyledHamburgerMenuContainer = styled.div`
  position: fixed;
  top: 90px;
  z-index: 10;
  height: 100vh;
  width: 80vw;
  /* background: rgba(225, 43, 60, 0.98); */
  background: rgba(224, 42, 60, 0.975);
  /* background:#D0484C;  */
  box-shadow: 10px 0px 10px #0001;

  left: ${props => (props.isOpen ? 0 : "-80vw")};
  transition: 0.25s;

  @media (min-width: 768px) {
    width: 350px;
  }
`

export default function HamburgerMenuContainer({
  isOpen,
  setHamburgerMenuIsOpen,
}) {
  return (
    <StyledHamburgerMenuContainer isOpen={isOpen}>
      <HamburgerMenu setHamburgerMenuIsOpen={setHamburgerMenuIsOpen} />
    </StyledHamburgerMenuContainer>
  )
}
