import React from "react"
import RootContainer from "./src/components/RootContainer"
import "./src/styles/reset.css"
import "./src/styles/global.css"
import "./src/styles/utils.css"
import "./src/styles/lexco.css"

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
function SiteWrapper({ element, props }) {
  return <RootContainer {...props}>{element}</RootContainer>
}

export default SiteWrapper
