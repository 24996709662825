import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import logo from "../../images/logo.png"
import Hamburger from "./Hamburger"
import HamburgerMenuContainer from "./HamburgerMenuContainer"
import OpacityLayer from "./OpacityLayer"
import VerticalSpacer from "../VerticalSpacer"

const StyledNavbar = styled.div`
  .fixedNavbar {
    position: fixed;
    width: 100vw;
    /* to be in front of panorama */
    z-index: 2;
    /* do not be transparent */
    background: white;
  }

  nav {
    height: 90px;
    box-shadow: 0 5px 20px 0 rgba(34, 30, 31, 0.1);
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
    height: 90px;
  }

  .left-section {
    display: flex;
    align-items: center;
    gap: 25px;
  }

  .right-section {
    display: none;
    font-family: "Avenir", var(--headings-font-family);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 3px;
  }

  @media (min-width: 768px) {
    .right-section {
      display: flex;
      gap: 60px;
      /* ne pas coller le côté droit */
      margin-right: 30px;
    }
  }

  .right-section a {
    display: grid;
    place-items: center;
    color: var(--gray1);
    transition: 0.1s;
  }
  .right-section a:hover {
    color: var(--red-lexco);
  }
`

export default function Navbar() {
  const [hamburgerMenuIsOpen, setHamburgerMenuIsOpen] = React.useState(false)
  function toggleMenu() {
    setHamburgerMenuIsOpen(hamburgerMenuIsOpen => !hamburgerMenuIsOpen)
  }

  return (
    <StyledNavbar>
      <OpacityLayer isOpen={hamburgerMenuIsOpen} onMouseDown={toggleMenu} />
      <HamburgerMenuContainer
        isOpen={hamburgerMenuIsOpen}
        setHamburgerMenuIsOpen={setHamburgerMenuIsOpen}
      />

      <nav className="fixedNavbar">
        <div className="container flex-between">
          <div className="left-section">
            <Hamburger onClick={toggleMenu} />
            <Link onMouseDown={() => setHamburgerMenuIsOpen(false)} to="/">
              <img height="70px" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="right-section">
            <Link to="/#lawyers">AVOCATS</Link>
            <Link to="/#services">SERVICES</Link>
            <Link to="/#contact">CONTACT</Link>
          </div>
        </div>
      </nav>
      <VerticalSpacer height="90px" />
    </StyledNavbar>
  )
}
