import styled from "styled-components"

const Hamburger = styled.div`
  cursor: pointer;
  font-size: 25px;
  position: relative;
  width: 1.25em;
  height: 0.7em;
  border-top: 0.2em solid #222;
  border-bottom: 0.2em solid #222;

  &:before {
    content: "";
    position: absolute;
    top: 0.25em;
    left: 0px;
    width: 100%;
    border-top: 0.2em solid #222;
  }
`

export default Hamburger
