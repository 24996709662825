import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledFooter = styled.div`
  footer {
    background-color: rgb(55, 55, 57);
    font-size: 15px;
    color: rgb(211, 213, 211);
  }
  #upperFooter {
    padding-top: 24px;
    padding-bottom: 70px;
  }

  .grid-container {
    gap: 24px; /* gap vertical entre chaque section */
  }

  footer .vertical-flex {
    gap: 8px;
    /* gap vertical entre chaque lien */
  }

  footer a {
    text-decoration: none;
    color: inherit;
  }

  footer .smallHeading {
    color: rgb(224, 42, 60);
    margin-bottom: 20px;
  }

  footer .smallHeading a {
    color: inherit;
  }

  #lowerFooter {
    height: 40px;
    background-color: rgb(83, 83, 85);
    text-align: center;
    line-height: 40px;
    font-size: 9px;
  }

  @media (min-width: 600px) {
    footer .grid-container {
      grid-template-columns: repeat(3, auto);
      justify-content: space-between;
    }
    #lowerFooter .container #copyright {
      font-size: 12px;
    }
  }

  @media (min-width: 1200px) {
    footer .grid-container {
      grid-template-columns: repeat(3, 1fr);
      /* on ne veut pas trop d'écart en chaque section, on se limite à 150 px d'écart */
      gap: 150px;
      justify-content: left;
    }
  }
`

export default function Footer() {
  const year = new Date().getFullYear()
  return (
    <StyledFooter>
      <footer>
        <div id="upperFooter">
          <div className="container">
            <div className="grid-container">
              <div>
                <h5 className="smallHeading">
                  <div>À propos</div>
                </h5>
                <div className="vertical-flex">
                  <div>Qui sommes nous</div>
                  <Link to="/Honoraires">Honoraires</Link>
                  <Link to="/Conditions">Conditions d'utilisation</Link>
                  <Link to="/Conditions">Confidentialité et Sécurité</Link>
                  <Link to="/Conditions">Mentions légales</Link>
                </div>
              </div>
              <div>
                <h5 className="smallHeading">
                  <div>Nos services</div>
                </h5>
                <div className="vertical-flex">
                  <div>Droit civil</div>
                  <div>Droit des affaires</div>
                  <div>Droit du travail</div>
                  <div>Droit de la famille</div>
                  <div>Droit immobilier</div>
                </div>
              </div>
              <div>
                <h5 className="smallHeading">Contact</h5>
                <div className="vertical-flex">
                  <a href="tel:0494511616">
                    <b>04 94 51 16 16</b>
                  </a>
                  <a href="mailto:cabinet@lexcoavocats.com">
                    cabinet@lexcoavocats.com
                  </a>
                  <span>64 Via Nova</span>
                  <span>Pôle d'Excellence Jean Louis</span>
                  <span>83600 FREJUS</span>
                </div>
              </div>
            </div>
            {/* fin du grid-container*/}
          </div>
          {/* fin du container div*/}
        </div>
        {/* fin du upperFooter*/}
        <div id="lowerFooter">
          <div className="container">
            <span id="copyright">
              COPYRIGHT © {year} LEX&amp;CO - CABINET D'AVOCATS - TOUS DROITS
              RÉSERVÉS.
            </span>
          </div>
          {/* fin du container div*/}
        </div>
        {/* fin du lowerFooter*/}
      </footer>
    </StyledFooter>
  )
}
