import React from 'react'
import styled from "styled-components"

const StyledVerticalSpacer = styled.div`
height:${props=>props.height};
`
export default function VerticalSpacer({height = "20px"}) {
  return (
    <StyledVerticalSpacer height={height}/>
  )
}
